@import '../../../../styles/dimensions';
@import '../../../../styles/breakpoints';
@import '../../bento-styles.module';
@import '../../../../styles/slick';

.container {
    @extend %containerbase;
}

.flexContainer {
    align-items: stretch;
    display: flex;
    flex-direction: row;
    margin: $gap-small-medium $gap-small-medium 0 $gap-small-medium;
    max-width: 1360px;
    width: 100%;

    @include screen-max-width('lg') {
        flex-direction: column;
        margin: 0 0 $gap-small-medium 0;
    }
}

.carousel :global .slick-track {
    align-items: flex-start;
}
