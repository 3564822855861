@import './breakpoints';
@import './dimensions';
@import './colors';
@import './theme';

// Slick Carousel
.carousel {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: fit-content;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    touch-action: pan-y;
    -webkit-touch-callout: none;
    user-select: none;
    width: 100%;
    z-index: 0;

    :focus {
        outline: none;
    }

    :global .slick-list {
        display: block;
        margin: 0;
        overflow: hidden;
        padding: 0;
        position: relative;
        width: 100%;
    }

    :global .slick-track,
    :global .slick-list {
        transform: translate3d(0, 0, 0);
    }

    :global .slick-track {
        //display: block;
        display: flex;
        flex-direction: row;
        left: 0;
        position: relative;
        top: 0;
    }

    /** Hack to force it stick to bottom. :( */
    // stylelint-disable-next-line selector-max-compound-selectors
    :global .slick-slide > div {
        display: flex;
    }
}

@include screen-min-width('medium') {
    .carousel :global .slick-track {
        align-items: flex-end;
        justify-content: space-between;
    }
}

.button {
    bottom: 0;
    margin: auto 0;
    position: absolute;
    top: 0;
    z-index: 1;
}

.chevron {
    background: radial-gradient(circle, theme-var('background'), transparent);
}

.button:hover,
.button:focus {
    @include hoverme(1.2);
}

.button:active svg {
    filter: brightness(0.5);
}

.button-left {
    left: 0;
    padding-left: $gap-small;
}

.button-right {
    padding-right: $gap-small;
    right: 0;
}
